interface Variant {
  [key: string]: string;
}

export const variants = ["map", "youtube"] as const;

export const variantClasses = {
  map: "w-full h-[280px]",
  youtube: "w-full h-4/5",
} as Variant;
