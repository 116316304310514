<template>
  <section class="flex h-full items-center justify-center">
    <slot v-if="variant === 'map'">
      <div :class="[variantClass]">
        <iframe
          :src="url"
          width="100%"
          height="100%"
          loading="lazy"
          allow="fullscreen"
        ></iframe>
      </div>
    </slot>
    <slot v-else>
      <iframe
        :id="`yt-player-${url}`"
        width="100%"
        height="100%"
        :src="`https://www.youtube.com/embed/${url}?autoplay=1`"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        loading="lazy"
      ></iframe>
    </slot>
  </section>
</template>

<script setup lang="ts">
import { computed as vComputed, PropType, ref as vRef } from "vue";
import { variantClasses } from "./const";
import { variantType } from "./types";

const props = defineProps({
  variant: {
    type: String as PropType<variantType>,
    default: "youtube",
  },
  url: {
    type: String,
    default: undefined,
  },
  videoKey: {
    type: String,
    default: null,
  },
});
const variantClass = vComputed<string>(() => variantClasses[props.variant]);
</script>
