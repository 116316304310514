import { useRoute as vUseRoute } from "vue-router";
import { Page } from "~/types/page";
import { getPageMetaConfig } from "~/utils/getMetaPageConfig";
import { getDynamicSections } from "~/utils/getDynamicSections";
import {
  createError,
  extractLastSegment,
  extractValueBeforeLastSegment,
  removeStringFromUrl,
} from "~/utils";
import { checkParentData } from "~/utils/checkParentData";

async function fetchPage(pageSlug: string, parentSlug: string | undefined) {
  const { find } = useStrapi4();

  const { data } = await find<Page[]>("pages", {
    filters: {
      slug: pageSlug,
    },
  });
  const page = data?.length > 0 ? data[0] : undefined;

  if (!page) {
    throw createError(
      `PageNotFoundError`,
      `Data for the page wasn't found in the Strapi backend.`
    );
  }
  checkParentData(page, parentSlug);

  return { page };
}

async function fetch(slug?: string) {
  const strapiUrl = removeStringFromUrl(useStrapiUrl(), "api");
  const route = vUseRoute();
  const pageSlug = slug ?? extractLastSegment(route.path);
  const parentSlug = slug
    ? undefined
    : extractValueBeforeLastSegment(route.path);

  try {
    const { page } = await fetchPage(pageSlug, parentSlug);
    const routePath = route.fullPath.toString();
    const { sections, topSection } = getDynamicSections(page);

    const pageMetaConfig = page?.attributes?.metadata
      ? getPageMetaConfig({
          page,
          strapiUrl,
          routePath,
        })
      : {};

    return {
      flagCampaign: page?.attributes?.flagCampaign,
      gridVariant: page?.attributes?.gridVariant,
      pageMetaConfig,
      sections,
      topSection,
    };
  } catch (error) {
    throw createError(
      "DataFetchError",
      `Error fetching data: ${error.message}`
    );
  }
}

export function useCmsifiedPageRendered() {
  return { fetch };
}
